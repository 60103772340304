@use '../../styles/scss/' as *;


.fly{
    animation: fly 2s forwards;
}
.body{
    position: absolute;
    top: 50%;
    margin-left: -50px;
    left: 50%;
    animation: speeder 0.4s linear infinite;


    > span{
        height: 5px;
        width: 35px;
        background: white;
        position: absolute;
        top: -19px;
        left: 60px;
        border-radius: 2px 10px 1px 0;

        > span{
            width: 30px;
            height: 1px;
            background: white;
            position: absolute;
            animation: fazer1 .2s linear infinite;
        }
        > span:nth-child(2){
            top: 3px;
            animation: fazer2 .4s linear infinite;
            
        }
        > span:nth-child(3){
            top: 1px;
            animation: fazer3 .4s linear infinite;
            animation-delay: -1s;
        }
        > span:nth-child(4){
            top: 4px;
            animation: fazer4 1s linear infinite;
            animation-delay: -1s;
        }
        

    }

    & .base{
        span{
            position: absolute;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 100px solid white;
            border-bottom: 6px solid transparent;

            &:before {
                content: "";
                height: 22px;
                width: 22px;
                border-radius: 50%;
                background: white;
                position: absolute;
                right: -110px;
                top: -16px;
              }
          
              &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 0 solid transparent;
                border-right: 55px solid white;
                border-bottom: 16px solid transparent;
                top: -16px;
                right: -98px;
              }
        }
    }

    & .face{
        position: absolute;
        height: 12px;
        width: 20px;
        background: white;
        border-radius: 20px 20px 0 0;
        transform: rotate(-40deg);
        right: -125px;
        top: -15px;
      
        &:after {
          content: "";
          height: 12px;
          width: 12px;
          background: white;
          right: 4px;
          top: 7px;
          position: absolute;
          transform: rotate(40deg);
          transform-origin: 50% 50%;
          border-radius: 0 0 2px 2px;
        }
    }

    
}

.longfazers {
    position: absolute;
    width: 100%;
    height: 100%;
  
    span {
      position: absolute;
      height: 2px;
      width: 20%;
      background:  lighten($main-color,80);
  
      &:nth-child(1) {
        top: 20%;
        animation: lf .6s linear infinite;
        animation-delay: -5s;
      }
  
      &:nth-child(2) {
        top: 40%;
        animation: lf2 .8s linear infinite;
        animation-delay: -1s;
      }
  
      &:nth-child(3) {
        top: 60%;
        animation: lf3 .6s linear infinite;
      }
  
      &:nth-child(4) {
        top: 80%;
        animation: lf4 .5s linear infinite;
        animation-delay: -3s;
      }
    }
  }

  #loading {
    position: absolute;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    left: 49%;
    top: 58%;
    color: white;
    margin-left: -20px;
  }
  