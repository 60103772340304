@use '../../styles/scss/' as *;



.art_main{
    background-image: linear-gradient(37deg, lighten($main-color,0) , lighten($main-color,2) );
    .artwork_container{
      padding: 0.5rem 1rem 2rem 0.5rem;
      
        .my_drawings{
            .gallery{
                list-style: none;
                margin: 0;
                padding: 0;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(20rem,1fr));
                grid-auto-rows: minmax(20rem, auto);
                grid-gap: 0.71rem;
                grid-auto-flow: dense;

                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: all 0.5s;
                    border-radius: 5px;
                    // &:hover{
                    //     transform: scale(1.05);
                    //     box-shadow: $box-shadow;
                    // }
                  }
            }
        }
    }
}



@media (min-width: 480px) {
    .gallery > li:first-child {
      grid-area: 1 / 1 / span 2/ span 2;
    }
    
    .gallery > li:nth-child(3n) {
      grid-column: span 2;
    }
  }
  