.type_normal { --color: #C2C2A1; }

.type_flying { --color: #BAB0D5; }

.type_ghost { --color: #735797; }

.type_dark { --color: #333; }

.type_steel { --color: #CCCCDE; }

.type_ground { --color: #B1736C; }

.type_poison { --color: #7C538C; }

.type_grass { --color: #48D0B0; }

.type_fire { --color: #FB6C6C; }

.type_electric { --color: #FFD86F; }

.type_fairy { --color: #f469a9; }

.type_bug { --color: #C3CE75; }

.type_fighting { --color: #d6b591; }

.type_water { --color: #609FB5; }

.type_psychic { --color: #9B7FA6; }

.type_ice { --color: #7FCCEC; }

.type_rock { --color: #a6aab6; }

.type_dragon { --color: #F9BE00; }