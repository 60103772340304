@use '../../styles/scss/' as *;

@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500');

@keyframes rocket-movement { 100% {transform: translate(2500px,-900px);} }
@keyframes spin-earth{ 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); transition: transform 20s; } }
@keyframes move-astronaut{
    100% { -webkit-transform: translate(-160px, -160px); transform:translate(-160px, -160px); }
}

@keyframes rotate-astronaut{
    100% { -webkit-transform: rotate(-720deg); transform:rotate(-720deg); }
}


@keyframes glow-star{
    40% { -webkit-opacity: 0.3; opacity: 0.3;  }
    90%,100% { -webkit-opacity: 1; opacity: 1; -webkit-transform: scale(1.2); transform: scale(1.2); border-radius: 999999px;}
}

.spin-earth-on-hover{
    
    transition: ease 200s !important;
    transform: rotate(-3600deg) !important;
}


.error_main{

    margin: 0;
    width: 100vw;
    height: 100vh;
    // font-family: 'Dosis', sans-serif;
    font-weight: 300;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
overflow: hidden;
}

.error_container{
    // background: url(http://salehriaz.com/404Page/img/bg_purple.png);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(37deg, lighten($main-color,0) , lighten($main-color,9) );

    

    .stars{
        background: url('../../../public/overlay_stars.svg');
        background-repeat: repeat;
        background-size: contain;
        width: 100vw;
        height: 100vh;
        background-position: left top;

        .glowing_stars{
            
            .star{
            position: absolute;
            border-radius: 100%;
            background-color: #fff;
            width: 3px;
            height: 3px;
            opacity: 0.83;
            will-change: opacity;
            &:nth-child(1){
                top: 80%;
                left: 25%;
                animation: glow-star 2s infinite ease-in-out alternate 1s;
            }
            &:nth-child(2){
                top: 20%;
                left: 40%;
                animation: glow-star 2s infinite ease-in-out alternate 3s;
            }
            &:nth-child(3){
                top: 25%;
                left: 25%;
                animation: glow-star 2s infinite ease-in-out alternate 5s;
            }
            &:nth-child(4){
                top: 75%;
                left: 80%;
                animation: glow-star 2s infinite ease-in-out alternate 7s;
            }
            &:nth-child(5){
                top: 90%;
                left: 50%;
                animation: glow-star 2s infinite ease-in-out alternate 9s;
            }
        }

    }
        
    }
    

    .central-body{
        // padding: 17% 5% 10% 5%;
        @include flex(center,center);
        text-align: center;
        width: 100%;
        flex-direction: column;
        height: 100vh;
        .error_msg{
            color: lighten($secondary-red,60);
            text-align: justify;
            padding: 0 38%;
            font-size: 1vw;
            // font-family: 'Poppins';
            margin-top: 1rem;
            margin-bottom: 0.1rem;
            font-weight: 500;
        }
        .image-404{
            position: relative;
            z-index: 100;
            width: 300px;
            width: 25%;
            margin: 15px auto;
            pointer-events: none;
        }
        

        .btn-go-home{
            position: relative;
            z-index: 200;
            margin: 15px auto;
            // width: 100px;
            padding: 10px 15px;
            border: 1px solid $secondary-red;
            border-radius: 10px;
            font-weight: 400;
            display: block;
            color: white;
            text-align: center;
            text-decoration: none;
            letter-spacing : 2px;
            font-size: 11px;
            // font-size: 1vw;
            transition: all 0.3s ease-in;
        
            &:hover{
                background-color: $secondary-red;
                color: #fff;
                transform: scale(1.05);
                box-shadow: 0px 20px 20px rgba(0,0,0,0.1);
            }


            @media  (max-width:800px) {
           font-size: 6px;
        }
        @media  (max-width:500px) {
            font-size: 5px;
            padding: 6px 10px ;
            border-radius: 4px;
         }
         
        
        }

    }
    .objects{


        img{
            z-index: 90;
            pointer-events: none;
        }

        .object_rocket{
            z-index: 105;
            position: absolute;
            width: 80px;
            transform: translateX(-50px);
            top: 75%;
            pointer-events: none;
            animation: rocket-movement 60s linear infinite both running;
        }
        
        .object_earth{
            position: absolute;
            top: 20%;
            left: 15%;
            z-index: 90;
            width: 100px;
         animation: spin-earth 50s infinite linear both;
         &:hover{
            transition: ease 200s !important;
            transform: rotate(-3600deg) !important;
         }
        }

        .object_moon{
            width: 80px;
            position: absolute;
            top: 15%;
            left: 20%;
        /*
            transform: rotate(0deg);
            transition: transform ease-in 99999999999s;
        */
        // animation: spin-earth 10s infinite linear both;
        }

        .box_astronaut{
            z-index: 110 !important;
            position: absolute;
            top: 60%;
            width: 140px;
            right: 20%;
            will-change: transform;
            animation: move-astronaut 50s infinite linear both alternate;

            .object_astronaut{
                animation: rotate-astronaut 200s infinite linear both alternate;
            }
            
        }


        @media  (max-width:1200px) {
       
            .object_moon{
             
                
                left: 22%;
            }
            .box_astronaut{
                // width: 120px;
                // top: 40%;

                right: 7%;
            }
    }
        
        @media  (max-width:1000px) {
            .object_rocket{
                width: 60px;
            }
            .object_earth{
                width: 90px;
            }
            .object_moon{
                width: 70px;
                
                left: 25%;
            }
            .box_astronaut{
                width: 120px;
                // top: 40%;

                right: 5%;
            }
    }
    @media  (max-width:800px) {
        .object_rocket{
            width: 40px;
        }
        .object_earth{
            width: 70px;
        }
        .object_moon{
            width: 70px;
            top: 12%;

            left: 22%;
        }
        .box_astronaut{
            width: 100px;
            top: 70%;

            right: 8%;
        }
}

@media  (max-width:500px) {
    .object_rocket{
        width: 30px;
    }
    .object_earth{
        width: 50px;
    }
    .object_moon{
        width: 40px;
    }
    .box_astronaut{
        width: 70px;
        top: 70%;

        right: 1%;
    }
}



    }
}



@media only screen and (max-width: 600px){

    
    .box_astronaut{
        top: 70%;
    }
    
    .central-body{
        padding-top: 25%;
    }
}