@use '../../styles/scss/' as *;



// Blog Card
.blog-slider {
    width: 95%;
  position: relative;
    max-width: 800px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
  transition: all .3s;
  
   
  @media screen and (max-width: 992px) {
    max-width: 680px;
        height: 400px;
  }
  
  @media screen and (max-width: 768px) {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
  
  
  @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 350px;
  }
  
  &__item {
    display: flex;
    align-items: center;
    
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
    
    &.swiper-slide-active {
      .blog-slider__img {
        img {
          opacity: 1;
        transition-delay: .3s;
        }
      }
      .blog-slider__content {
        > * {
      
        opacity: 1;
        transform: none;

      @for $i from 0 to 15 {
        &:nth-child(#{$i + 1}) {
          transition-delay: $i * 0.1 + 0.3s;
        }
      }

    }
      }
    }
    
  }
  
  &__img {
     // width: 40%;
    width: 300px;
    flex-shrink: 0;
    height: 300px;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
    border-radius: 20px;
    transform: translateX(-80px);
   
    overflow: hidden;
    
    &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
      border-radius: 20px;
    opacity: 0.8;
}
    
    img {
      width: 100%;
    height: 100%;
    object-fit: cover;
      display: block;
       opacity: 0;
      border-radius: 20px;
    transition: all .3s;
    }
    
    
    @media screen and (max-width: 992px) {
      // width: 45%;
    }
    @media screen and (max-width: 768px) {
    transform: translateY(-50%);
      width: 90%;
  }
    @media screen and (max-width: 576px) {
      width: 95%;
    }
     @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 270px;
  }
  }
  
  &__content {
    // width: 60%;
    padding-right: 25px;
    @media screen and (max-width: 992px) {
      // width: 55%;
    }
    @media screen and (max-width: 768px) {
          margin-top: -80px;
    text-align: center;
    padding: 0 30px;
    }
    
    @media screen and (max-width: 576px) {
      padding: 0
    }
    > * {
      opacity: 0;
      transform: translateY(25px);
      transition: all .4s;
      
      
    }
    
  }
  
  &__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
  }
  
  &__title {
    font-size: 24px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 20px;
  }
  
  &__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  
  &__button {
        display: inline-flex;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
        justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
    
  }
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
    
}
  &__pagination {
   position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px!important;
    text-align: center;
    left: auto!important;
    top: 50%;
    bottom: auto!important;
    transform: translateY(-50%);
    @media screen and (max-width: 768px) {
     transform: translateX(-50%);
          left: 50%!important;
      top: 205px;
    width: 100%!important;
    display: flex;
    justify-content: center;
    align-items: center;
    }
     &.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 0;
       @media screen and (max-width: 768px) {
         margin: 0 5px;
       }
}
    
    .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 10px;
    background: #062744;
    opacity: 0.2;
      transition: all .3s;
      &-active {
    opacity: 1;
    background: #fd3838;
    height: 30px;
    box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
        
        @media screen and (max-width: 768px) {
          height: 11px;
          width: 30px;
        }
}
}
    
  }
  
}



// Exp Card

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@700;900&display=swap');





.experience_card 
{
    position: relative;
    // max-width: 550px;
    // min-width: 400px;
    // min-height: 300px;
    background-color: white;
    border-radius: 1.2rem;
    // filter: drop-shadow(-6px 8px 16px #27030a);
    box-shadow: 4px 13px 30px 1px rgba(3, 3, 3, 0.2);
    transition: 0.35s ease-in-out;

    &:hover{
        transform: scale(1.02);
        

       
    }
    .cards 
    {
        // position: absolute;
        width: 100%;
        height: 100%;

        
        .card 
        {
            position: relative;
            height: 100%;
            
                .card_header 
                {
                    display: flex;
                    position: relative;
                    height: 6.4rem;
                    flex-direction: row !important;
                    align-items: center;
                    background-color: #2e2e2e;
                    background-image: linear-gradient(147deg, lighten($main-color,20) , $main-color );
                  
                    padding: 1.5em 2em;
                    gap: 0 20px;
                    // width: 560px;
                    // margin-left: -5px;
                    border-radius: 1.2rem;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;


                    .card_image 
                    {
                        position: absolute;
                        left: 2em;
                        width: 4.5em;
                        height: 4.5em;
                        object-fit: fill;

                        border-radius: 50%;
                        border: 2px solid white;
                        padding: 2px;
                        transition: all .5s;
                        // box-shadow: $box-shadow;

                        @include tablet{
                          width: 3.5rem;
                          height: 3.5rem;
                        }
                        @include mobile{
                          width: 2.5rem;
                          height: 2.5rem;
                        }
                    }

                    .card_info 
                    {
                        position: absolute;
                        /* display: none; */
                        left: 7.9em;
                        display: flex;
                        flex-direction: column;
                        font-family: 'Raleway', sans-serif;
                        transition: all .5s;

                        @include tablet{
                          left: 6.9em;
                        }
                        @include mobile{
                          left: 5.9em;
                        }

                              .card_title 
                              {
                                  font-size: 1.4rem;
                                  color: white;

                                  @include tablet{
                                    font-size: 1.5rem;
                                  }
                                  @include mobile{
                                    font-size: 1.3rem;
                                  }

                                    @media screen and (max-width: 900px){
                   font-size: 2vw;
           }
  @media screen and (max-width: 500px){
    font-size: 3vw;
  }

                              }

                              .card_subtitle 
                              {
                                  font-size: 1.4rem;
                                  color: #f54568;
                                  font-family: 'Raleway', sans-serif;
                                  font-weight: 700;
                                  font-weight: bold;

                                  @include tablet{
                                    font-size: 1.5rem;
                                  }
                                  @include mobile{
                                    font-size: 1.3rem;
                                  }
                                  
                                  @media screen and (max-width: 900px){
                                    font-size: 2vw;
                            }
                   @media screen and (max-width: 500px){
                     font-size: 3vw;
                   }
                              }
                              .card_date{
                                font-size: 0.9rem;
                                color: rgb(209, 209, 209);
                                @include tablet{
                                  font-size: 0.8rem;
                                }
                                @include mobile{
                                  font-size: 0.5rem;
                                }

                              }
                    }

                }

                .card_body 
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: justify;
                    height: calc(100% - 105px);
                    .card_quote 
                {
                    position: relative;
                    padding: 2rem 2rem 2rem 2.5rem;

                    font-size: 0.8rem;
                    @media screen and (max-width: 900px){
                      font-size: 1.4vw;
              }
     @media screen and (max-width: 500px){
       font-size: 2.4vw;
     }

                                          li::before { /* add the new bullet point */
                        display: inline-block;
                          content: '';
                          -webkit-border-radius: 0.375rem;
                          border-radius: 0.375rem;
                          height: 0.55rem;
                          width: 0.55rem;
                          margin-right: 0.5rem;
                          background-color: $main-color;
                          
                      }


                      li {
                        text-indent: -1.2em; /* this is only needed if you want to align wrapped text */
                      }
                }
                }
                
        }
    }
    

}








.edu_slider {
  font-family: 'Raleway', sans-serif;
  width: 90%;
  position: relative;
  max-width: 1200px;
  margin: auto;
  background: rgba(255, 255, 255, 1);
  margin-left: 5.6rem;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  height: 100%;
  transition: all 0.3s;
  border-radius: 0.7rem;


  // background-image: url('../../../public/assets/bg/bg1.jpg');
  // background-repeat: no-repeat;
  // background-size: 200%;
  // background-position: -1rem;
  // background-blend-mode: luminosity;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    
    width: 100%;  
    height: 100%;
    opacity: 0.06;
    background-image: url('../../../public/assets/bg/bg1.jpg');
    background-repeat: no-repeat;
    background-position: -1rem;
    background-size: 200%;

    @media screen and (max-width: 525px){
      background-size: cover;
    }

  }

  &:hover{
    transform: scale(1.02);
  }

  @media screen and (max-width: 992px) {
    max-width: 680px;
    height: 400px;
  }

  @media screen and (max-width: 768px) {
    // min-height: 500px;
    height: auto;
    margin: 180px auto;
  }

  @media screen and (max-height: 500px) and (min-width: 992px) {
    // height: 350px;
    height: auto;
  }

  &_item {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &_img {
    // width: 40%;
    width: 300px;
    flex-shrink: 0;  
    height: 300px;
    box-shadow: 4px 13px 30px 1px rgba(0, 0, 0, 0.2);
    transform: translateX(-80px);
    overflow: hidden;
    border-radius: 2rem;
    background-color: $body-bg;

   
  @media screen and (max-width: 992px) {

  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 500px){
    width: 200px;
    height: 200px;
  }



    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: block;
      background-image: linear-gradient(147deg, lighten($main-color,0) 40%, lighten($main-color,30) 84%);

      // opacity: 1;
      border-radius: 2rem;
      transition: all 0.3s;
      border-radius: 2rem;
    
  

    }

    @media screen and (max-width: 992px) {
      // width: 45%;
    }
    @media screen and (max-width: 768px) {
      transform: translateY(-50%);
      width: 90%;
      
    }
    @media screen and (max-width: 576px) {
      width: 95%;
      min-width: 180px;
    }
    @media screen and (max-height: 500px) and (min-width: 992px) {
      // height: 270px;
    }
  }

  &_content {
    // width: 60%;
    padding-right: 25px;
    @media screen and (max-width: 992px) {
      // width: 55%;
    }
    @media screen and (max-width: 768px) {
      margin-top: -80px;
      text-align: left;
      padding: 0 30px;
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      padding: 0;
    }
    > * {
      // opacity: 0;
      transform: translateY(25px);
      transition: all 0.4s;
    }
  }

  &_code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
    @media (min-width:1340px) and (max-width: 1716px) {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 900px){
      font-size: 2vw;
    }
    @media screen and (max-width: 500px){
      font-size: 3vw;
    }
  }

  &_title {
    font-size: 20px;
    font-weight: 700;
    color: #0d0925;
    margin-bottom: 2px;
    @media (min-width:1340px) and (max-width: 1716px) {
      font-size: 0.8rem;
    }

    
  @media screen and (max-width: 900px){
    font-size: 3vw;
  }
  @media screen and (max-width: 500px){
    font-size: 4vw;
  }
  

  }

  &_text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
    @media (min-width:1340px) and (max-width: 1716px) {
      font-size: 0.6rem;
    }
    @media screen and (max-width: 900px){
      font-size: 2vw;
    }
    @media screen and (max-width: 500px){
      font-size: 3vw;
    }
  }

  &_period {
    display: inline-flex;
    background-image: linear-gradient(147deg, lighten($main-color,50) 50%, $main-color 74%);
    padding: 5px 10px;
    margin-bottom: 20px;
    font-size: 0.7rem;
    border-radius: 50px;
    color: white;
    // color: #fff;
    box-shadow: 2px 2px 5px rgba(59, 59, 59, 0.4);
    // text-decoration: none;
    font-weight: 600;
    // justify-content: center;
    // text-align: center;
    letter-spacing: 1px;
    @media (min-width:1340px) and (max-width: 1716px) {
      font-size: 0.4rem;
    }
    
  }
  // .swiper-container-horizontal > .swiper-pagination-bullets,
  // .swiper-pagination-custom,
  // .swiper-pagination-fraction {
  //   bottom: 10px;
  //   left: 0;
  //   width: 100%;
  // }
}




// portfolio-card
.portfolio_card{
  // width: 320px;
  width: 363px;
  width: 100%;
   
  min-height: 245px;
  // margin: 0 20px;
  background: $main-color;
  // border-radius: 16px;
  border-radius: 6px;
  box-shadow: 2px 1px 5px 1px rgba(9, 9, 9, 0.292);
  
  cursor: pointer;
  position: relative;
  max-height: 300px;
  overflow: hidden;
  .portfolio_card_header{

    border-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 210px;
    transition: 0.9s;

    position: absolute;
    top: 0;
    bottom: 100px;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    &:hover {
      // .portfolio_card_header{
        top: 0px;
        left: 0px;
        position: absolute;
        height: 500px;
        width: 100%;
        background-image: cover;
        opacity: 0.08;
        border-radius: 16px;
        z-index: 1;
        transition: 1.5s;
        overflow: hidden;
      // }
      + .portfolio_card_section {
        top: 0px;
        left: 0px;
        position: absolute;
        background: transparent;
        z-index: 0;
        transition: all 1s ease-in-out;
        height: 300px;
        overflow: hidden;
        .portfolio_card_title{
      
          h1{
            font-size: 26px;
            margin: 8px 0px;
            font-weight: 700;
        
          }
        
          a{

           display: none;
          }
        }

        > h3 {
          font-size: 20px;
          color: grey;
          font-weight: 400;
          letter-spacing: 2px;
          transition: all 1.5s ease-in-out;
          text-transform: uppercase;
        }
        > p {
          font-size: 18px;
          margin: 8px 0px;
          color: black;
          transition: all 1.5s ease-in-out;
          font-weight: 600;
        }
        > small {
          transition: all 1.5s ease-in-out;
          font-size: 14px;
          margin: 8px 0px;
          color: black;
      }
      }
    }

  }
  .portfolio_card_section{
    overflow: auto;
    padding: 16px;
    background: transparent;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 200px;
    height: calc(0.40 * 500px);

    transition: 1.5s;
    .portfolio_card_title{
      @include flex(center,space-between);
    
      h1 {
        
        transition: 1.5s;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }
      // a{
      //   color: beige;


      
      //   &:hover{
      //     border-radius: 0.5rem;
      //     color:#f54568;
         
      //     &::after{
      //       content: ">";
           
      
      //       // position: absolute;
      //       // display: block;
      //       // width: 4rem;
      //       // height: 1px;
      //       // border-radius: 2rem;
      //       // background:#f54568;
            

      //     }
       
      //   }
      // }

      a {
        background-image: linear-gradient(
          to right,
          #f54568,
          #f54568 50%,
          beige 50%
        );
        background-size: 200% 100%;
        background-position: -100%;
        display: inline-block;
        padding: 0px 0;
        position: relative;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all 0.3s ease-in-out;
      }
      
      a:before{
        content: '';
        background: #f54568;
        display: block;
        position: absolute;
        bottom: -3px;
        border-radius: 1rem;
        left: 0;
        width: 0;
        height: 3px;
        transition: all 0.3s ease-in-out;
      }
      
      a:hover {
       background-position: 0;
      }
      
      a:hover::before{
        width: 100%;
      }
    }
    
    > h3 {
      font-size: 16px;
      color: black;
      font-weight: 400;
      margin: 8px 0px;
      transition: 1.5s;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    > p {
      margin: 8px 0px;
      color: black;
      font-weight: 600;
    }
    > small {
      margin: 8px 0px;
      color: grey;
      display: none;
    }
  }








}



// certificate card

.certificate_card{
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
  // width: 270px;
  display: inline;

  img{
    // height: 200px;
    // width: 200px;
    // object-fit: cover;
  }  
  h1{
    font-size: 16px;
    color: $main-color;
    font-weight: bold;

  }
  h2{
      font-size: 12px;
 
  }
  h3{
    font-size: 10px;
    // font-weight: bold;
    // margin: 10px 0 5px 0;
    color: #111;
  } 
  .certificate_desc{
    font-size: 8px;
    color: rgb(99, 99, 99);
    margin: 0;
  }
  .certificate_heading_img{
    font-style: italic;
    margin: 0 15px 15px 15px;
    padding: 10px;
    background: #fff;
    position: relative;
    margin-bottom: 35px;
    border-radius: 6px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

    &::after{
      content: "";
      width: 0;
      height: 0;
      border-top: 20px solid #fff;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 20px);
    }
  }
}