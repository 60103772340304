@use '../../styles/scss/' as *;


.home{
    margin-left: 5.6rem;

    @media (max-width: 1199px)
    {
      margin-left: 0;
    }

}

.jumbotron{

    padding: 4rem 2rem;
    color: #ffffff;
    padding-top: 80px;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    border-radius: 0;
    margin-bottom: 0;
    overflow: hidden;
    min-height: 100vh;
    height: 101vh;

@include mobile{
    font-size: 1.2rem;
}
    @include small_device{
        font-size: 0.7rem;
    }

    & .d-flex{
        display: flex;
    }
    & .align-items-center{
        align-items: center;
    }

   & .container{

        // position: relative;
        z-index: 1;

    }

    &::before{
        position: absolute;
   
        display: block;
        content: "";
        opacity: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $body-bg;
        background: linear-gradient(to right, $main-color,transparent);
     
    }



& .rectangle{
    // width: 2000px;
    width: 110vw;
    height: 400px;
    background: $main-color;
    background: linear-gradient(to right, $main-color,$body-bg);
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    box-shadow: 0px 20px 30px 7px rgba(9, 21, 54, 0.25);

    &_1{
        transform-origin: 120% 254% 0;
        left: 0;
        transform: scale(1) rotate(-10deg);
        opacity: 0;
        animation: grow1 ease 1s forwards;
    }
    &_2{
        transform-origin: 55% 100% 0;
        transform: scale(1) rotate(50deg);
        opacity: 0;
        animation: grow2 ease 1s forwards;
    }

    &_transparent{
        border: 1.5rem solid rgba(255, 255, 255, 0.5);
        position: absolute;
        display: block;
        z-index: -2;
        animation: floating-slow ease-in-out 12s infinite;
        
        &_1{
            width: 40rem;
            height: 40rem;
            left: -20%;
            bottom: -30%;
         
        }
        &_2{
            width: 45rem;
            height: 45rem;
            right: -10%;
            top: 5%;
        }
    }

}


& .circle{
    border: 2px solid rgba(255, 255, 255, 0.295);
    position: absolute;
    display: block;

    animation: fadeInFromTop .5s linear forwards, floating ease 4s infinite;
    border-radius: 50%;
    &_1{
        
        width: 50px;
        height: 50px;
        left: 5%;
        top: 50%;
        transform-origin: 50% 50%;
    }
    &_2{
        width: 70px;
        height: 70px;
        left: 83%;
        top: 20%;
        transform-origin: 50% 50%;
    }
    &_3{
        width: 40px;
        height: 40px;
        right: 25%;
        top: 80%;

    }
    

}


& .triangle{
    position: absolute;
    
    &_1{
        right: 0;
        animation: fadeInFromTop .5s linear forwards, floating ease-in-out 6s infinite;
        img{
            opacity: 0.2;
            height: 50px;
            width: 50px;
            transform: rotate(30deg);
        }
    }
    &_2{
        top: 30%;
        left: 20%;
        animation: fadeInFromTop .5s linear forwards, floating ease-in-out 8s infinite;
        img{
            opacity: 0.2;
            width: 75px;
            height: 75px;
            transform: rotate(15deg);
        }
    }

    &_3{
        top: 80%;
        left: 15%;
        animation: fadeInFromTop .5s linear forwards, floating ease-in-out 10s infinite;

        img{
            opacity: 0.2;
            width: 45px;
            height: 45px;
            transform: rotate(40deg);
        }

    }

    &_4{

        top: 60%;
        right: 15%;
        animation: fadeInFromTop .5s linear forwards, floating ease-in-out 5s infinite;
        img{
            opacity: 0.2;
            width: 45px;
            height: 45px;
            transform: rotate(-40deg); 
        }

    }

}


@include tablet{
    h1{
        font-size: 2.5rem;
        
    }

    & .rectangle{
        width: 1200px;
        height: 250px;
    

        &_1{
            transform-origin: 175% 347% 0;
        }
        &_2{
            width: 1500px;
            transform-origin:32% -25% 0;
        }


        
    &_transparent{

        &_1{
            width: 30rem;
            height: 30rem;
            left: -5%;
            bottom: -10%;
        }
        &_2{
            width: 35rem;
            height: 35rem;
            right: -10%;
            top: 5%;
        }
    }
    
    }

}

@include mobile{
    h1{
        font-size: 1.5rem;
        
    }

    & .rectangle{
        width: 1500px;
        height: 200px;
    

        &_1{
            transform-origin: 210% 380% 0;
        }
        &_2{
            width: 2000px;
            transform-origin:20% -200% 0;
        }

        
    &_transparent{

        &_1{
            width: 25rem;
            height: 25rem;
            left: -5%;
            bottom: -10%;
        }
        &_2{
            width: 35rem;
            height: 35rem;
            right: -10%;
            top: 5%;
        }
    }
    
    }



}





.fade_reveal{
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 4rem 2rem;
    padding-right: 4rem;
    animation: fadeInFromBottom 2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s forwards;
    overflow-wrap: anywhere;

    @include mobile{
        padding-top: 0rem;
    }
    
    .home_content{
        padding-left: 2rem;
        text-align: left;

            .row{
                display: flex;
                flex-wrap: wrap;
                --bs-gutter-x: 1.5rem;
                --bs-gutter-y: 0;
                margin-top: calc(-1 * var(--bs-gutter-y));
                margin-right: calc(-0.5 * var(--bs-gutter-x));
                margin-left: calc(-0.5 * var(--bs-gutter-x));
            }

            .col-md-7{
                flex: 1 1 auto;
                // width: 58.33333333%;


                // @include small_tablet{
                //     flex: 1 1 auto;
                //     text-align: center;
                //     align-items: center;
                //     justify-content: center;
                //     display: flex;
                //     flex-direction: column;
                // }
            
            }
            .col-md-5{
                flex: 1 1 auto;
                align-items: flex-start;
                align-self: flex-start;
        }
            
        
        .home_header {
            padding-top: 6rem;
        
            @include mobile{
                padding-top: 0rem;
            }
             
            .heading {
                font-size: 2.4em !important;
     
            }
            
            .heading_name {
                font-size: 2.5em !important;
               
            }
                    
                        
            .main_name {
                color: lighten($main-color,60);
                font-family:'Google Sans Bold',  'Google Sans Medium';
                text-transform: uppercase;
                text-shadow: 2px 2px black;
            }
  
            .download_resume_btn{
                margin-top: 2rem;
                width: 14rem;
             
                border: 2px solid lighten($main-color,50);
                .mas
                   {
                    padding: 1rem;
                    font-size: 1rem;
                    color: lighten($main-color,50);
                    
                }
                   button{
                    background-color: lighten($main-color,50);
                    font-size: 1rem;
                   }
            }

        }
 
    }


  }


}


  .Typewriter__wrapper {
    font-size: 2.2em !important;
    color: lighten($main-color,60) !important;
    font-weight: 600 !important;

  }
  .Typewriter__cursor {
    font-size: 2.4em !important;
    color: lighten($main-color,60) !important;
  }
  
  @media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em !important;
      font-weight: 500 !important;
      position: absolute !important;
    }
    .Typewriter__cursor {
      display: none !important;
    }
  }
  
  .wave {
    animation-name: wave-animation; 
    animation-duration: 2.1s; 
    animation-iteration-count: infinite; 
    transform-origin: 70% 70%; 
    display: inline-block;
  }
  
  .col-md-5{

    svg{
        
        width: 100%;
        height: 800px;
        margin-right: 15rem;
        
     
        @include tablet{
            width: 100%;
            height: 545px;
            
        }

        @include small_tablet{
            margin-top: 70px;
            height: 400px;
            
        }

        @include mobile{
                
                width: 100%;
                height: 500px;

            
        }
        @include small_device{
            width: 100%;
            // height: 100%;
        }


    }
  }


  @media (min-width: 768px){
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
}


@media (min-width: 768px){
.col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  
}}