@use '../../styles/scss/' as *;



  
  .timeline {
    position: relative;
    // max-width: 1200px;
    margin: 0 auto;
   margin-top: 3rem;

    &::after{

            content: '';
            position: absolute;
            width: 6px;
            border-radius: 2rem;
            background-color: $main-color;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -3px;


            

          
    }

    @include md_tablet{
        &::after {
            left: 31px;
            }
    }

    .timeline_container {
        
        padding: 10px 40px;
        position: relative;
        // background-color: rgba(223, 223, 255, 0.7);
        width: 50%;
        &::after{
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            right: -17px;
            background-color: $body-bg;
            border: 4px solid $main-color;
            border-radius: 50%;
            top: 15px;
            z-index: 1;
        }


                        
                .content {
                    // padding: 20px 30px;
                    // background-color: #ccf;
                    // color: #00f;
                    position: relative;
                    border-radius: 1.2rem;
                }
                
                &::before {
                    content: " ";
                    height: 0;
                    position: absolute;
                    top: 22px;
                    width: 0;
                    z-index: 1;
                    right: 32px;
                    border: medium solid $main-color;
                    border-width: 10px 0 10px 10px;
                    border-color: transparent transparent transparent $main-color;
                  }

                 
                  @include md_tablet{
                    width: 100%;
                    padding-left: 70px;
                    padding-right: 25px;
                    &::before{

                        left: 61px;
                        border: medium solid lighten($main-color,20);
                        border-width: 10px 10px 10px 0;
                        border-color: transparent lighten($main-color,20) transparent transparent;

                    }
                }

        
      }
      .left {
        left: 0;
    
        @include md_tablet{

            &::after{
                left: 15px;
            }

        }
        

      }
      
      .right {
        left: 50%;
        &::before {
            content: " ";
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            left: 31px;
            border: medium solid lighten($main-color,20);
            border-width: 10px 10px 10px 0;
            border-color: transparent lighten($main-color,20) transparent transparent;
          }
          &::after {
            left: -16px;
          }

          @include md_tablet{

            &::before{
                left: 61px;
                border: medium solid lighten($main-color,20);
                border-width: 10px 10px 10px 0;
                border-color: transparent lighten($main-color,20) transparent transparent; 
            }

            &::after{
                left: 15px;
            }
            left: 0%;
        }

     
      }
      

  }




  
 
  
//   @media screen and (max-width: 600px) {
   
//     // .timeline::after {
//     // left: 31px;
//     // }
    
//     // .timeline_container {
//     // width: 100%;
//     // padding-left: 70px;
//     // padding-right: 25px;
//     // }
    
//     // .timeline_container::before {
//     // left: 60px;
//     // border: medium solid #00f;
//     // border-width: 10px 10px 10px 0;
//     // border-color: transparent #00f transparent transparent;
//     // }
  
//     .left::after, .right::after {
//     left: 15px;
//     }
    
//     .right {
//     left: 0%;
//     }
//   }