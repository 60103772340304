@use '../../styles/scss/' as *;


.social_media_div{
    font-size: 1.5em;
    // margin-left: 50px;    
    @include small_tablet{
        font-size: 1.3rem;
        margin-top: 20px;
    }
}