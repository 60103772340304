@use '../../styles/scss/' as *;
.logo_wrapper {
    height: 100vh;
    width: 100vw;
    background-color: $main-color;
    align-items: center;
    justify-content: center;
    @include flex(center,center);
    & .screen{
        height: 100%;
        @include flex(center,center);
        overflow: hidden;
        border-radius: 50%;
        animation: grow 1s forwards;
        background: url('../../../public/overlay_stars.svg');
    }

    & #logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

  }



