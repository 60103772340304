@use '../../styles/scss/' as *;



/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.details_main{
  display: block;
.breadcrumbs{
  padding: 20px 20px;
  background-color: $main-color;
  color: white;
  display: block;
  min-height: 40px;
  .detail_container{
    width: 100%;
    // padding-right: 15px;
    // padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .project_header{

      @include flex(center,space-between);
  h2{
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    li + li {
      padding-left: 10px;
      &::before {
        display: inline-block;
        padding-right: 10px;
        color: #6c757d;
        content: "/";
      }

     
    }
    a{
      &:hover{
        color: $secondary_red;
      }
    }
  }
}
}

}

.portfolio-details{
  padding: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  

  .detail_container{
    width: 100%;
    // padding-right: 15px;
    // padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10rem;
    .portfolio-details-container {
      position: relative;
      // width: 100%;
      // max-width: 1500px;
      // padding-left: 10rem;
      // padding-right: 10rem;

      .portfolio-details-carousel {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;

        .error_img{
          height: 600px;
          background-color: whitesmoke;
          
        }
        
      }

      .portfolio-info {
        padding: 25px;
        position: absolute;
        right: 0;
        bottom: -70px;
        background: #fff;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        z-index: 2;
        border-radius: 0.5rem;

        // background-image: ;
        
        &::before {
          content: ' ';
          display: block;
          position: absolute;
          left: 0;
          top: 0px;
          
          width: 100%;  
          height: 100%;
          opacity: 0.09;
          background-image: url('../../../public/assets/bg/bg1.jpg');
          background-repeat: no-repeat;
          background-position: -1rem;
          background-size: 200%;
          z-index: -1;




        }

        h3 {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 10px;
          padding-bottom: 10px;
          
          border-bottom: 0.5px solid $main-color;
          
        }
        
        ul {
          list-style: none;
          padding: 0;
          font-size: 15px;
          li + li {
            margin-top: 10px;
          }
          a{
            color: lighten($color: $main-color, $amount: 50);
            &:hover{
              color: $secondary_red;
            }
          }

          .tech_container{
            // @include flex(center,space-between);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        p{
          margin-left: 5px;
          background-image: linear-gradient(to right,$main-color ,lighten($main-color,30));
          color: white;
          border-radius: 0.3rem;
          font-size: 0.8rem;
          @media only screen and (max-width:900px){
            font-size: 1vw;
          }
          @media only screen and (max-width:802px){
            font-size: 1.5vw;
          }
          @media only screen and (max-width:402px){
            font-size: 2vw;
          }
          
          
          align-self: center;
          
    

          padding: 0.1rem 0.5rem;
          // padding-left: ;
          // margin-bottom: 0.2rem;
          margin: 0.2rem;
          transition: all 2s;
          cursor: pointer;
          &:hover{
            background-image: linear-gradient(to right,$main-color,$pokemon_red);
          }
        }


        @media only screen and (min-width:802px){
              max-width: 300px;
        }
      

        
      }
        }
        
        
        @media only screen and (max-width: 1000px) and (min-width:802px){
        
          max-width: 260px;
          padding: 12px;
          h3{
            font-size: 15px;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }

          ul {
            list-style: none;
            padding: 0;
            font-size: 10px;
            li + li {
              margin-top: 5px;
            }
         
          }
      
      }


      }

    }



    .portfolio-description {
      margin-top: 6rem;
      padding-top: 50px;
      // padding-left: 50px;
      .feature_title{
        margin-top: 2rem;
        color: lighten($color: $main-color, $amount: 20);
      }
      h2 {
        color: lighten($color: $main-color, $amount: 10);
        width: 50%;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        padding: 0 0 0 0;
      }
      .attribution{

        padding: 10px;
        margin: 20px 0; 
        border: 1px solid transparent; 
        border-radius: 4px;
        background-color: #d9edf7;
        border-color: #bce8f1;
        color: #31708f;
      }

      .feature{
        display: grid;
  padding: 0rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
 

  column-gap: 1rem;
  row-gap: 0.5rem;
  margin-top: 2rem;
  //background-color: orange;

  .item{
    // margin: 0.5rem;
    padding: 0.8rem;
    color: white;
    font-size: 10px;
    text-align: left;
    font-family: sans-serif;
    text-transform: uppercase;
    border-radius: .5rem;
      
    
    display: flex;
    // justify-content: center;
    align-items: center;
    
    box-shadow: 0 1rem 4rem rgba(0,0,0,.25);
    transition: all .2s;
    // background-image: linear-gradient(to right,$main-color 25%, lighten($main-color,20) 95%);
    background-color: rgba($main-color, 0.6);
    font-weight: 600;
    font-family: 'Poppins';
    border: 1px solid transparent;
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  // &--1 {
  //   background-color: blue;
  //  /*  grid-row: 1 / 3;
  //   grid-column: 2 / 4; */
  // }

  // &--2 {
  //   background-color: purple;
  // }
  
  // &--3 {
  //   background-color: $pokemon_red;
  // }
  
  // &--4 {
  //   background-color: green;
  // }
  
  // &--5 {
  //   background-color: teal;
  // }
  
  // &--6 {
  //   background-color: navy;
  // }
    &:hover {
    
      background-color: lighten($color: $main-color, $amount: 80);
      background-image: linear-gradient(to right,lighten($main-color,80), lighten($main-color,60) 95%);

      border: none;
      color: $main-color;
      transform: translate(0,-0.3rem);
      box-shadow: 0 1rem 4rem rgba(0,0,0,.50);

    }
  }
    
  

      }
    }
       




  }

}


}



@media (min-width: 576px){
.detail_container, .detail_container-sm {
    // max-width: 540px;
}
}
@media (min-width: 768px){
.detail_container {
    // max-width: 720px;
}
}
@media (min-width: 992px){
  .detail_container, .detail_container-lg, .detail_container-md, .detail_container-sm {
      // max-width: 960px;
  }
  }
@media (min-width: 1200px){
.detail_container, .detail_container-lg, .detail_container-md, .detail_container-sm, .detail_container-xl {
    max-width: 1140px;
}
}


  
  @media (max-width: 992px) {
    .breadcrumbs h2 {
      margin: 0 0 10px 0;
    }
  }
  

  
  @media (max-width: 768px) {
    .project_header {
      display: block !important;
    }
    .project_header{ ol {
      display: block;
    }
  }
    .breadcrumbs {
      ol{ li {
      display: inline-block;
    }
  }
  }
  }
  






  .img-fluid{
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
  }
  
.slider-dots{
  // position: absolute;
  // bottom: -25px;
  // display: block;
  // width: 100%;
  // padding: 0;
  // margin: 0;
  // list-style: none;
  // text-align: start;
  // margin-top: 2rem;

  display: block;
  text-align: left;
  margin-top: 5px;

  li{
    // position: relative;
    // display: inline-block;
    // width: 80px;
    // height: 20px;
    // margin: -10px 5px;
    // padding: 0;

    display: inline-block;
    margin: 0 10px 0 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #ddd !important;

//     @media (max-width: 500px) {
//       width: 60px;
//   }
//   @media (max-width: 403px) {
//     width: 40px;
// }
    cursor: pointer;
    // img{
    //   border-radius: 0.5rem;
    
    // }
     


    button{
      font-size: 0;
      line-height: 0;
      display: block;
      width: 12px; 
      height: 12px; 
       padding: 5px;
       border-radius:50%; 
       cursor: pointer; 
       color: transparent; 
      border: 0;
       outline: none; 
       background: #ddd;
       
       &:hover{
        background-color: $main-color;
       }
   
      //  &::before{
      //   font-family: 'slick';
      //   // font-size: px;
      //   // background-color: $main-color;
      //   border-radius: 50%;
      //   line-height: 12px;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   width: 12px;
      //   height: 12px;
      //   content: '•';
      //   text-align: center;
      //   opacity: .25;
      //   color: #ddd;
      //   -webkit-font-smoothing: antialiased;



      //  }

      }

  }

  .slick-active{
    button{
    background-color: $main-color;
    // button{
    //   &::before{
    //     background-color: $main-color!important;
    //     opacity: 1!important;
    //   }
    // }
    }
  
  }
}



  
  @media (max-width: 801px) {
    .portfolio-details .portfolio-description h2 {
      width: 100%;
    }
    .portfolio-details .portfolio-info {
      position: static!important;
      &::before{
        display: none!important;
      }
      margin-top: 90px;
    }
  }

  .slick-list{
    
    border-radius: 0.5rem!important;
  }

  .slick-track{
    border-radius: 0.5rem!important;
    min-height: 500px;

    @media (max-width: 600px) {
        min-height: auto;
    }

  }