/*
    Animations
*/
@keyframes spin
{
    from { transform: rotate( 0 ); }
    to { transform: rotate( 360deg ); }
}

@keyframes pull-up-center
{
    from { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
    to { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
}

@keyframes pull-down-center
{
    from { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
    to { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
}

@keyframes pull-up
{
    from { transform: translateY( 10% ); opacity: 0; }
    to { transform: translateY( 0 ); opacity: 1; }
}

@keyframes fade-in
{
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out
{
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fill
{
    from { width: 0; }
}





@keyframes fadeInFromTop {
    from {
      opacity: 0;
      transform: translateY(-20px); }
    to {
      opacity: 1;
      transform: translateY(0); } 
    
}


@keyframes fade-in-right {
    from {
      opacity: 0;
      transform: translateX(-100vw) rotate(-15deg); }
    to {
      opacity: 1;
      transform: translateX(0) rotate(-15deg); } }
  
  @keyframes grow1 {
    from {
      opacity: 0;
      transform: scale(2) rotate(-10deg); }
    to {
      opacity: 1;
      transform: scale(1) rotate(-10deg); } }
  
  @keyframes grow2 {
    from {
      opacity: 0;
      transform: scale(2) rotate(50deg); }
    to {
      opacity: 1;
      transform: scale(1) rotate(50deg); } }
  

      @keyframes floating {
        0% {
          transform: translate(0%, 0%) rotate(25deg); }
        25% {
          transform: translate(5%, 15%) rotate(25deg); }
        50% {
          transform: translate(10%, 5%) rotate(25deg); }
        75% {
          transform: translate(0%, 15%) rotate(25deg); }
        100% {
          transform: translate(0%, 0%) rotate(25deg); } }
      
      @keyframes floating-slow {
        0% {
          transform: translate(0%, 0%) rotate(25deg); }
        25% {
          transform: translate(1%, 3%) rotate(25deg); }
        50% {
          transform: translate(2%, 1%) rotate(25deg); }
        75% {
          transform: translate(0%, 3%) rotate(25deg); }
        100% {
          transform: translate(0%, 0%) rotate(25deg); } }


          @keyframes grow {
            0% {
              transform: scale(0);
 
              
            }
            10% {
              transform:  scale(0.6);
              border-radius: 10%;
              height: 100%;
              width: 100%;
            }



            100% {
              transform: scale(1);
              border-radius: 0%;
              height: 100%;
              width: 100%;
            }
          }



          

          @keyframes fly {
            0% {
              transform: translateX(-2000px);
              height: 100%;
              width: 100%;
              
            }

            50% {
              transform: translateX(300px);

              height: 100%;
              width: 100%;
            }
            

            100% {
              height: 100%;
              width: 100%;
            }
          }

          
@keyframes fazer1 {
  0% {
    left: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}

@keyframes fazer2 {
  0% {
    left: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}

@keyframes fazer3 {
  0% {
    left: 0;
  }
  100% {
    left: -50px;
    opacity: 0;
  }
}

@keyframes fazer4 {
  0% {
    left: 0;
  }
  100% {
    left: -150px;
    opacity: 0;
  }
}

@keyframes speeder {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}



@keyframes lf {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf2 {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf3 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}
@keyframes lf4 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}




@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(5rem); }
  to {
    opacity: 1;
    transform: translateY(0); } 
  
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}



@keyframes ani {
  from {
   -webkit-mask-position: 0 0;
   mask-position: 0 0;
  }
 
  to {
   -webkit-mask-position: 100% 0;
   mask-position: 100% 0;
  
  }
 }
 
 @keyframes ani2 {
  from {
   -webkit-mask-position: 100% 0;
   mask-position: 100% 0;
  }
 
  to {
   -webkit-mask-position: 0 0;
   mask-position: 0 0;

  }
 }