@mixin flex($align-items,$justify-content){
    display: flex;
    align-items: $align-items;
    justify-content: $justify-content;
}


@mixin nav-item-grid {
    display: grid;
    place-items: center;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 1rem;
  }


@mixin button($bcolor,
  $url,
  $x1,
  $y1,
  $bor,
  $col) {
   background: $bcolor;
   -webkit-mask: url($url);
   mask: url($url);
   -webkit-mask-size: $x1 $y1;
   mask-size: $x1 $y1;
   border: $bor;
   color: $col;
}
  