@use './variables' as vars;

@mixin mobile {
    @media only screen and (max-width:vars.$mobile-width){
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width:vars.$tablet-width){
        @content;
    }
}

@mixin small_device {
    @media only screen and (max-width:vars.$xs-width){
        @content;
    }
    
}

@mixin small_tablet {
    @media only screen and (max-width:vars.$small-tablet-width) {
        @content;
    }
    
}

@mixin md_tablet {
    @media only screen and (max-width:vars.$md-tablet-width) {
        @content;
    }
    
}
@mixin large_device {
    @media only screen and (min-width:vars.$lg-width){
        @content;
    }
}

@mixin  large_tablet {
    @media only screen and (max-width:vars.$large-tablet-width) {
        @content;
    }
}
@mixin  device_850 {
    @media only screen and (max-width:vars.$device-850) {
        @content;
    }
}

@mixin  device_700 {
    @media only screen and (max-width:vars.$device-700) {
        @content;
    }
    
}
@mixin  device_1365 {
    @media only screen and (max-width:vars.$device-1365) {
        @content;
    }
}